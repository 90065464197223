@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,300;1,400;1,500;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Ubuntu", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.Content {
  flex-grow: 1;
}
@media (max-width: 425px) {
  html {
    font-size: 11px;
  }
  .MuiBadge-root .MuiBadge-badge {
    height: 15px;
    min-width: 10px;
  }
}
